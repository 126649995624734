function Unauthorized($mdDialog) {
  const _showAlert = () => {
    // Appending dialog to document.body to cover sidenav in docs app
    // Modal dialogs should fully cover application
    // to prevent interaction outside of dialog
    $mdDialog
      .show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title(`Unauthorized`)
          .textContent("Sorry, you're unauthorized to view this job")
          .ariaLabel("Not authorized")
          .ok("Ok!")
      )
      .finally(() => {
        this.onShown();
      });
  };

  this.$onChanges = cObject => {
    if (cObject.error && cObject.error.currentValue) {
      _showAlert();
    }
  };
}

angular
  .module("sq.jobs.unauthorized.component", [])
  .component("sqJobUnauthorized", {
    bindings: {
      error: "<",
      onShown: "&"
    },
    template: `
    <span id='job-error'></span>
  `,
    controller: Unauthorized,
    controllerAs: "$ctrl"
  });
